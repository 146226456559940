/*----------------------------------------------Main------------------------------------------------*/
.App {
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0;
  font-weight: 400;
  display: flex;
  flex-direction: column;
  scroll-behavior: smooth !important;
}

* {
  box-sizing: border-box;
  font-family: "League Spartan", sans-serif;
}

.body {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow-x: hidden;
  scroll-behavior: smooth !important;
  overflow-y: scroll;
}

.row {
  flex-direction: row;
  display: flex;
  width: 100vw;
  justify-content: center;
}

.column {
  display: flex;
  flex-direction: column;
}

.footer {
  display: flex;
  flex-direction: row;
  width: 100vw;
  background-color: #313131;
  padding: 5vh 0;
  justify-content: center;
}

/*----------------------------------------------blocks------------------------------------------------*/
.body-row {
  flex-direction: row;
  display: flex;
  background-color: #1c1c1c;
}

.beam-exp-image {
  background-color: #079de8;
  border-radius: 10px 0 0 10px;
}

.ccs-exp-image {
  background-color: #fefefe;
  border-radius: 10px 0 0 10px;
}

.root-exp-image {
  background-color: #ff5715;
  border-radius: 10px 0 0 10px;
}

.block-content {
  margin: 5px 25px 5px 25px;
  font-family: "League Spartan", sans-serif;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 2px;
  word-spacing: 0;
  color: #fefefe;
  text-align: center;
}

.experience-row {
  color: #fefefe;
  flex-direction: row;
  border-radius: 10px;
  background-color: #313131;
  box-shadow: 0 0 2px 1px #363636;
  display: flex;
  margin: 2vh 1vw 0;
}

.body-block {
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: #1c1c1c;
  flex-wrap: wrap;
  width: 100vw;
  padding-bottom: 5vh;
  box-shadow: 0 1px 0 3px #1c1c1c;
}

.info-block {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  background-color: #1c1c1c;
  padding: 25px 0 0;
}

.landing {
  display: flex;
  flex-direction: row;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
}

.landing-content {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  background-color: rgba(45, 45, 45, 0.16);
  align-items: center;
}

.about-me {
  display: flex;
  flex-direction: row;
}

.company-logo {
  width: 50px;
  height: 50px;
  border-radius: 25px;
}

.contact-content {
  display: flex;
  flex-direction: column;
  padding: 5px;
  color: #a2a1a1;
  align-items: center;
}

.contact-column {
  display: flex;
  flex-direction: column;
}

.logos {
  display: flex;
  line-height: normal;
  justify-content: left;
}

.name {
  font-size: 22px;
  text-align: left;
  line-height: normal;
  margin: 0 0 5px 5px;
  letter-spacing: 1px;
}

.title {
  font-size: 20px;
  text-align: left;
  line-height: normal;
  margin: 0 0 5px 5px;
  letter-spacing: 1px;
}

.threshold {
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  background-color: #1c1c1c;
  box-shadow: 0 0 5px 1px #1c1c1c;
  height: 0;
  width: 100%;
}

/*----------------------------------------------elements------------------------------------------------*/

li {
  border-radius: 5px;
  list-style-type: none;
  list-style-position: inside;
  margin-bottom: 5px;
  padding: 0;
}

ul {
  padding: 0;
  margin: 0;
}

h3 {
  margin: 0;
  padding: 0;
}

/*----------------------------------------------icons------------------------------------------------*/

.gmail {
  padding: 2px 4px 2px 4px !important;
  height: max-content;
  border-radius: 10px;
  line-height: 0;
  margin: 4px;
  width: 32px;
}

.github {
  padding: 2px 4px 2px 4px !important;
  height: max-content;
  border-radius: 10px;
  line-height: 0;
  margin: 4px;
  width: 32px;
}

.linkedin {
  padding: 2px 4px 2px 4px !important;
  height: max-content;
  border-radius: 10px;
  line-height: 0;
  margin: 4px;
  width: 32px;
}

.logo {
  -webkit-filter: invert(1);
  filter: invert(100%);
}

.linkedin-logo {
  -webkit-filter: invert(1);
  filter: invert(100%);
}

.gmail-logo:hover {
  cursor: pointer;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.MuiTooltip-tooltip {
  padding: 10px 5px 10px !important;
}

/* setup tooltips */
.tooltip {
  position: relative;
}
.tooltip:before,
.tooltip:after {
  display: block;
  opacity: 0;
  pointer-events: none;
  position: absolute;
}
.tooltip:after {
  border-right: 6px solid transparent;
  border-bottom: 6px solid rgba(0, 0, 0, 0.75);
  border-left: 6px solid transparent;
  content: "";
  height: 0;
  top: 20px;
  left: 20px;
  width: 0;
}
.tooltip:before {
  background: rgba(0, 0, 0, 0.75);
  border-radius: 2px;
  color: #fff;
  content: attr(data-title);
  font-size: 14px;
  padding: 6px 10px;
  top: 26px;
  white-space: nowrap;
}

/* the animations */
/* fade */
.tooltip.fade:after,
.tooltip.fade:before {
  transform: translate3d(0, -10px, 0);
  transition: all 0.15s ease-in-out;
}
.tooltip.fade:hover:after,
.tooltip.fade:hover:before {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}
