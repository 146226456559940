.about-me-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100vw;
}

.about-me-column {
  display: flex;
  flex-direction: column;
  min-width: 60vw;
  max-width: 90vw;
  align-items: center;
  margin: 1vh 1vw;
  flex-wrap: wrap;
}

.about-me-text-title {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.about-me-text-content {
  display: flex;
  flex-direction: row;
  margin-top: 3vh;
  max-width: 75vw;
}

.about-me-text-small {
  font-family: "League Spartan", sans-serif;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 2px;
  word-spacing: 0;
  color: #fefefe;
  text-align: center;
}

.about-me-text-large {
  font-family: "League Spartan", sans-serif;
  font-size: 32px;
  font-weight: 600;
  letter-spacing: 2px;
  word-spacing: 0;
  color: #fefefe;
}
