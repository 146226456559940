.skills-section {
  flex-direction: row;
  display: flex;
  width: 100vw; /*width: 550px;*/
  justify-content: center;
  /*padding: 5vh 0;*/
}

.skills-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  max-width: 95vw;
}

.skill-column {
  display: flex;
  flex-direction: column;
  margin-top: 1vh;
  width: 400px;
}

/*.skills-block {*/
/*  display: flex;*/
/*  flex-direction: column;*/
/*  flex-wrap: wrap;*/
/*  max-width: 90vh;*/
/*  min-height: 60vh;*/
/*  justify-content: center;*/
/*}*/

.skill-row {
  flex-direction: row;
  display: flex;
  flex-wrap: wrap;
  padding: 1vh 0 2vh;
  max-width: 90vw;
  min-width: 70vh;
  justify-content: center;
}

.skills-margin {
  /*margin-top: 10px;*/
}

.top {
  border-radius: 25px 25px 0 0;
  border-color: #282828;
  border-style: solid;
  border-width: 1px 1px 0;
}

.middle {
  border-color: #282828;
  border-style: solid;
  border-width: 1px 1px 0;
}

.bottom {
  border-radius: 0 0 25px 25px;
  border-color: #282828;
  border-style: solid;
  border-width: 1px 1px 1px;
}

.skills-box-row {
  flex-direction: row;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  /*margin-top: 0.5vh;*/
  padding: 10px 10px;
  width: 100%;
  /*box-shadow: 0px 1px 0px .5px #ffffff;*/
}

.skill-text-large {
  font-family: "League Spartan", sans-serif;
  font-size: 32px;
  font-weight: 600;
  letter-spacing: 2px;
  word-spacing: 0;
  color: #fefefe;
}

.skills-text-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  /*border-color: #ffffff;*/
  /*box-shadow: 0px 1px 0px .5px #ffffff;*/
  width: 100%;
}

.small-skills-text {
  font-family: "League Spartan", sans-serif;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 2px;
  word-spacing: 0;
  color: #fefefe;
  /*text-shadow: #606060 2px 1px 0px;*/
}

.skill-text {
  margin: 0 10px 0 5px;
  font-family: "League Spartan", sans-serif;
  letter-spacing: 1px;
  color: #fefefe;
}

.skill {
  margin: 10px;
  min-height: 50px;
  min-width: 100px;
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  border-radius: 5px;
  background-color: #313131;
  box-shadow: 0 0 2px 1px #363636;
  transition: all 0.2s ease-in-out;
  position: relative;
}

.skill:hover .hidden-text-wrap {
  /*background-image: linear-gradient(to bottom right, #414141, #2f2f2f);*/
  box-shadow: 0 0 3px 3px #343434;
  visibility: visible;
  opacity: 1;
}

.skill:hover .hidden-text {
  transform: translateY(0);
}

.skill-image {
  margin: 0 5px 0 10px;
  filter: drop-shadow(0 0 0.3rem rgba(38, 38, 38, 0.72));
}

.hidden-text-wrap {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #343434;
  color: #fff;
  visibility: hidden;
  opacity: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition:
    opacity 0.2s,
    visibility 0.2s;
}

.hidden-text {
  transition: 0.2s;
  transform: translateY(1em);
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}
