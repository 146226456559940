.header {
  display: flex;
  flex-direction: row;
  background-color: #1c1c1c;
  box-shadow: 0 2px 5px 0 #1c1c1c;
  width: 100%;
}

.navbar {
  flex: 100%;
  display: flex;
  width: 100vh;
  height: 7vh;
  flex-direction: row;
  align-items: center;
}

.right-nav {
  display: flex;
  justify-content: right;
  flex: 80%;
  margin-right: 3vh;
}

.left-nav {
  display: flex;
  flex: 20%;
  justify-content: left;
  margin-left: 3vh;
  align-items: center;
}

.link {
  padding: 3px 1vh 0;
  font-family: "League Spartan", sans-serif;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 3px;
  transition-duration: 0.5s;
  color: #fefefe;
  text-decoration: none;
}

.logo-link {
  /*padding: 1vh;*/
  font-family: "League Spartan", sans-serif;
  font-size: 38px;
  font-weight: 700;
  letter-spacing: 0px;
  padding: 3px 0 0 3px;
  transition-duration: 0.5s;
  color: #fefefe;
}

.link:hover {
  background-color: rgba(255, 255, 255, 0.1);
}
