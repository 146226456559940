.intro-text-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 30vh;
}

.intro-footer {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  height: 60vh;
}

.intro-text-large {
  font-size: 38px;
  font-weight: 600;
  letter-spacing: 2px;
  word-spacing: 1px;
  color: rgba(254, 254, 254, 0.92);
}

.intro-text-small {
  font-size: 22px;
  font-weight: 500;
  letter-spacing: 2px;
  word-spacing: 2px;
  color: #fefefe;
  text-align: center;
}

.intro-text {
  font-family: "League Spartan", sans-serif;
  text-decoration: none;
  font-style: normal;
  font-variant: normal;
  text-transform: none;
}

.intro-text-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  max-width: 90vw;
}

.picture-block {
  background-image: url("../images/DSC_1204-min.jpg");
  background-color: #313131;
  display: block;
  background-attachment: fixed;
  background-position: left;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  overflow: hidden;
  height: 100vh;
  width: 100%;
  z-index: -1;
}

.styled-button {
  text-align: center;
  border-style: solid;
  border-width: 3px;
  border-color: rgba(30, 30, 30, 0.76);
  border-radius: 50px;
  height: 50px;
  width: 175px;
  background-color: #1c1c1c;
  box-shadow: 0 0 3px 1px rgba(30, 30, 30, 0.76);
  font-family: "League Spartan", sans-serif;
  font-size: 18px;
  color: rgb(254, 254, 254);
  padding: 10px 20px;
  letter-spacing: 2px;
  font-weight: 700;
  margin-bottom: 15vh;
  text-decoration: underline 0.12em rgba(0, 0, 0, 0);
  text-underline-offset: 2px;
  transition-duration: 0.3s;
}

.styled-button:hover {
  text-decoration-color: rgb(254, 254, 254);
  text-underline-offset: 4px;
  background-color: #282828;
  border-color: rgba(40, 40, 40, 0.76);
  box-shadow: 0 0 3px 1px rgba(33, 33, 33, 0.76);
}
