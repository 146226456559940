.email-input {
  background-color: #343434;
  color: #a2a1a1;
  height: 50px;
  max-width: 95vw;
  border-radius: 5px;
  border-width: 0;
  font-size: 22px;
  padding: 2px 10px;
}

.name-input {
  background-color: #343434;
  color: #a2a1a1;
  height: 50px;
  max-width: 95vw;
  border-radius: 5px;
  border-width: 0;
  font-size: 22px;
  padding: 2px 10px;
}

.message-text-area {
  background-color: #343434;
  color: #a2a1a1;
  width: 100%;
  max-width: 95vw;
  border-radius: 5px;
  border-width: 0;
  font-size: 22px;
  padding: 15px 10px;
}

.form-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  max-width: 95vw;
  flex-wrap: wrap;
}

.form-column {
  display: flex;
  flex-direction: column;
  max-width: 95vw;
  flex: 48%;
  flex-wrap: wrap;
  align-content: stretch;
  margin: 0 5px 10px;
}

.submit-button {
  border-style: solid;
  border-width: 3px;
  border-color: rgba(30, 30, 30, 0.76);
  border-radius: 50px;
  height: 50px;
  width: 175px;
  background-color: #343434;
  font-family: "League Spartan", sans-serif;
  font-size: 20px;
  color: #a2a1a1;
  padding: 10px 20px;
  letter-spacing: 3px;
  font-weight: 500;
  text-decoration: underline 0.12em rgba(0, 0, 0, 0);
  text-underline-offset: 2px;
  transition-duration: 0.3s;
}

.submit-button:hover {
  text-decoration-color: rgb(217, 216, 216);
  text-underline-offset: 4px;
  background-color: #282828;
  border-color: rgba(40, 40, 40, 0.76);
}

.submit-button:disabled {
  background: #343434;
  color: #a2a1a1;
  cursor: not-allowed;
  text-decoration: none;
}

.error-message {
  color: #a16a6a;
  margin-left: 5px;
}

.submit-button-enter {
  opacity: 0;
}
.submit-button-exit {
  opacity: 1;
}
.submit-button-enter-active {
  opacity: 1;
}
.submit-button-exit-active {
  opacity: 0;
}
.submit-button-enter-active,
.submit-button-exit-active {
  transition: opacity 200ms;
}
